import type { ExtractAtomValue } from "jotai";
import { useAtomValue } from "jotai/react";
import { useMediaQuery } from "usehooks-ts";

import { selectAdMode } from "@sunrise/ads";
import type { UpsellLinkContext } from "@sunrise/backend-ng-upsell";
import { useTranslator } from "@sunrise/translator";
import { upsellLinkQueryAtom } from "@sunrise/yallo-upsell";
import { Link, Text } from "@sunrise/yallo-web-components";

import styles from "./ads.module.css";

function getUpsellContextAndCopy(mode: ExtractAtomValue<typeof selectAdMode>): {
  label: string;
  upsellContext: UpsellLinkContext;
} {
  switch (mode) {
    case "replay":
      return {
        label: "ads_why_do_i_see_this_ad",
        upsellContext: "replay_ads",
      };
      break;
    case "live":
    default: {
      return {
        label: "ad_screen_no_more_ads_button",
        upsellContext: "ads",
      };
      break;
    }
  }
}

function UpsellButton() {
  const mode = useAtomValue(selectAdMode);
  const smallScreen = useMediaQuery("(width <= 800px");
  const { label, upsellContext } = getUpsellContextAndCopy(mode);
  const upsell = useAtomValue(upsellLinkQueryAtom(upsellContext));
  const t = useTranslator();

  if ((smallScreen && mode === "live") || !upsell.data) return;

  return (
    <Link
      className={styles.button}
      href={upsell.data.upsell_link}
      variant="none"
    >
      <Text size="small" variant="label">
        {t(label)}
      </Text>
    </Link>
  );
}

export { UpsellButton };

import type { ComponentProps, ReactElement } from "react";
import clsx from "clsx";

import type { Nullable } from "@sunrise/utils";

import { Text } from "../text";
import styles from "./air-time.module.css";

type AirTimeProps = {
  relativeDate: Nullable<string>;
  startDate: Nullable<Date>;
  endDate: Nullable<Date>;
  formattedStart: Nullable<string>; // formatted string 13:00
  formattedEnd: Nullable<string>; // formatted string 15:00
  size: Required<ComponentProps<typeof Text>["size"]>;
  variant?: ComponentProps<typeof Text>["variant"];
  isLive?: boolean;
} & CommonProps &
  AirTimeLabelProps;

export type AirTimeLabelProps = {
  startTimeLabel?: string;
  endTimeLabel?: string;
};

function AirTime({
  className,
  relativeDate,
  startDate,
  endDate,
  formattedEnd,
  formattedStart,
  size,
  isLive,
  startTimeLabel,
  endTimeLabel,
  variant,
}: AirTimeProps): ReactElement {
  const isEmpty = !relativeDate && !formattedStart && !formattedEnd;

  return (
    <Text className={className} size={size} variant={variant ?? "label"}>
      {relativeDate && (
        <time
          aria-label={relativeDate}
          className={clsx({ [styles.live]: isLive })}
          dateTime={startDate?.toISOString()}
        >
          {relativeDate}
        </time>
      )}
      {relativeDate && formattedStart && formattedEnd && " "}
      {formattedStart && formattedEnd && (
        <span className={styles.formattedStartEnd}>
          <time aria-label={startTimeLabel} dateTime={startDate?.toISOString()}>
            {formattedStart}
          </time>
          <span aria-hidden="true">{` – `}</span>
          <time aria-label={endTimeLabel} dateTime={endDate?.toISOString()}>
            {formattedEnd}
          </time>
        </span>
      )}
      {isEmpty ? <>&nbsp;</> : null}
    </Text>
  );
}

export { AirTime };

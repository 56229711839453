import { useEffect } from "react";
import { useAtomValue, useSetAtom } from "jotai";

import { scrollPositionAtom } from "../atoms/scroll-position.atom";
import { selectChannelListTask } from "../atoms/select-channel-list-task.atom";
/**
 * Either we auto-scroll to the active channel or we restore the scroll position.
 */
export function useChannelListScroll({
  scrollToIndex,
  scrollToPosition,
  isIndexInView,
  addEventListener,
}: {
  scrollToIndex: (index: number) => void;
  scrollToPosition: (position: number) => void;
  isIndexInView: (index: number) => boolean;
  addEventListener: (listener: (position: number) => void) => () => void;
}) {
  const task = useAtomValue(selectChannelListTask);
  const setter = useSetAtom(scrollPositionAtom);

  useEffect(() => {
    const handleScroll = (position: number) => {
      setter({ type: "set-position", payload: { position } });
    };

    return addEventListener(handleScroll);
  }, [setter, addEventListener]);

  useEffect(() => {
    if (task?.type !== "restore") {
      return;
    }

    scrollToPosition(task.position);

    setter({
      type: "completed-task",
      payload: { task },
    });
  }, [setter, task, scrollToPosition]);

  useEffect(() => {
    if (task?.type !== "auto-scroll") {
      return;
    }

    // Attempt to check if the item is already in view.
    const isInView = isIndexInView(task.index);

    if (!isInView) {
      scrollToIndex(task.index);
    }

    setter({
      type: "completed-task",
      payload: { task },
    });
  }, [setter, task, scrollToIndex, isIndexInView]);
}

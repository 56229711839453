import type { CSSProperties } from "react";
import clsx from "clsx";
import { useAtomValue } from "jotai/react";

import {
  type GuideChannel as GuideChannelType,
  type GuideChannelPlaceholder,
  selectorGuideSelection,
} from "@sunrise/yallo-guide";
import { Button, Text } from "@sunrise/yallo-web-components";

import { ChannelLogo } from "../channel-logo";
import styles from "./guide-channel.module.css";

type GuideChannelProps = {
  channel: GuideChannelType | GuideChannelPlaceholder;
  style?: CSSProperties;
};

export function GuideChannel({ channel, style }: GuideChannelProps) {
  const selection = useAtomValue(selectorGuideSelection);

  const id = "id" in channel ? channel.id : null;
  const logo = "logo" in channel ? channel.logo : null;
  const name = "name" in channel ? channel.name : null;

  return (
    <Button
      className={clsx([
        styles.channel,
        selection?.channelId === id && styles.active,
      ])}
      style={style}
      variant="filled"
    >
      <div className={styles.inner}>
        <Text className={styles.number} size="small" variant="label">
          {channel.channelNumber}
        </Text>
        <ChannelLogo className={styles.logo} logo={logo} name={name} />
      </div>
    </Button>
  );
}

import type { ComponentProps, ReactElement } from "react";

import { Toggle } from "./toggle";

type Props = Omit<ComponentProps<typeof Toggle>, "type" | "variant"> & {
  variant?: Extract<ComponentProps<typeof Toggle>["variant"], "selection">;
};

function Checkbox(props: Props): ReactElement {
  return <Toggle {...props} type="checkbox" />;
}

export { Checkbox };

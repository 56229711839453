// https://developer.mozilla.org/en-US/docs/Web/API/View_Transition_API
export function withViewTransition(callback: () => void) {
  if ("startViewTransition" in document) {
    return () => {
      document.startViewTransition(() => {
        callback();
      });
    };
  }

  return callback;
}

import { type ReactElement } from "react";
import * as Sentry from "@sentry/react";

import { TranslationProvider } from "@sunrise/translator";
import { getErrorMessage } from "@sunrise/yallo-error-mapping";

export type MonitoringErrorBoundaryProps = {
  children: JSX.Element;
  fallback?: Sentry.FallbackRender;
};

const fallback: Sentry.FallbackRender = ({ error, eventId }): ReactElement => {
  // TODO: Inject a QR code that contains the eventId for easy copy/pasta.
  // TODO: YALLOTV-13290 implement error page
  return (
    <TranslationProvider>
      <div>
        <h1>oh snap!</h1>
        <p>{getErrorMessage(error)}</p>
        <p>eventId: {eventId}</p>
        <p>at: {new Date().toISOString()}</p>
      </div>
    </TranslationProvider>
  );
};

export function ErrorBoundary(
  props: MonitoringErrorBoundaryProps,
): JSX.Element {
  return (
    <Sentry.ErrorBoundary
      fallback={props.fallback ?? fallback}
      onError={
        import.meta.env.MODE !== "production"
          ? (e) => console.error(e)
          : undefined
      }
    >
      {props.children}
    </Sentry.ErrorBoundary>
  );
}

import { useEffect } from "react";
import { useAtomValue, useSetAtom } from "jotai";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import { isDefined } from "@sunrise/utils";

import { channelListBoundariesVisibleAtom } from "../channels-for-channel-list.atom";

export function useSetBoundaries({
  firstVisibleChannelIndex,
  lastVisibleChannelIndex,
}: {
  firstVisibleChannelIndex: number;
  lastVisibleChannelIndex: number;
}) {
  const setBoundaries = useSetAtom(channelListBoundariesVisibleAtom);
  const isLegacy = useAtomValue(isLegacyBackendAtom);

  useEffect(() => {
    if (
      isLegacy ||
      !isDefined(firstVisibleChannelIndex) ||
      !isDefined(lastVisibleChannelIndex)
    ) {
      return;
    }

    setBoundaries({
      type: "index",
      start: firstVisibleChannelIndex,
      end: lastVisibleChannelIndex,
    });
  }, [
    firstVisibleChannelIndex,
    lastVisibleChannelIndex,
    setBoundaries,
    isLegacy,
  ]);
}

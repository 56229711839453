import { type ReactElement } from "react";
import { createPortal } from "react-dom";
import { clsx } from "clsx";
import { useAtomValue } from "jotai";
import logoUrl from "static/icons/logo.svg";
import { Link } from "wouter";

import { useTranslator } from "@sunrise/translator";
import { Button, Title } from "@sunrise/yallo-web-components";

import { route } from "@/config/route";
import { headerCloseActionAtom } from "@/modules/header/header-close-action.atom";
import { withViewTransition } from "@/utils/with-view-transition";

import { SuspendedErrorWrapper } from "../suspended-error-wrapper";
import {
  headerTitleAtom,
  LoginOrUpgradeButton,
  Navigation,
  SearchButton,
  Settings,
} from "./components";
import styles from "./header.module.css";

type HeaderProps = CommonProps;

function Header({
  "data-testid": dataTestId = "header",
}: HeaderProps): ReactElement | null {
  const headerTitle = useAtomValue(headerTitleAtom);
  const headerCloseAction = useAtomValue(headerCloseActionAtom);

  const t = useTranslator();
  const headerPortal = document.getElementById("header-portal");
  const mobileNavigationPortal = document.getElementById(
    "mobile-navigation-portal",
  );

  if (!headerPortal) throw new Error("#header-portal not found in the DOM");
  if (!mobileNavigationPortal)
    throw new Error("#mobile-navigation-portal not found in the DOM");

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {createPortal(
        <header
          className={clsx(styles.header, {
            [styles.scrolledDownTitle]: headerTitle.visible,
            [styles.closeAction]: !!headerCloseAction,
          })}
          data-testid={dataTestId}
        >
          <Link className={styles.logo} href={route.home.root()}>
            <img alt="" height={79} src={logoUrl} width={179} />
          </Link>
          <Navigation />
          <div className={styles.actions}>
            <SuspendedErrorWrapper>
              <LoginOrUpgradeButton />
            </SuspendedErrorWrapper>
            <SearchButton />
            <Settings />
          </div>
          <Button
            aria-hidden="true"
            className={clsx(styles.replacementTitle)}
            variant="none"
            onClick={scrollToTop}
          >
            <Title level="h1" size="medium" variant="title">
              {headerTitle.title}
            </Title>
          </Button>
          {!!headerCloseAction && (
            <Button
              className={styles.closeButton}
              icon="close"
              variant="text"
              hideLabel
              onClick={withViewTransition(headerCloseAction)}
            >
              {t("recordings_bulk_delete_close")}
            </Button>
          )}
        </header>,
        headerPortal,
      )}
      {createPortal(
        <div className={styles.mobileNavigation}>
          <Navigation size="small" />
        </div>,
        mobileNavigationPortal,
      )}
    </>
  );
}

export { Header };

import { useCallback, useEffect, useState } from "react";
import { captureException } from "@sentry/core";
import { useAtomValue, useSetAtom, useStore } from "jotai/index";
import { useAtomCallback } from "jotai/utils";

import {
  type ActionDialogAction,
  actionDialogClose,
  actionDialogOpen,
  dialogAtom,
  type DialogDescription,
  selectCurrentlyOpenedDialog,
} from "@sunrise/dialogs";
import { BaseError, errorAtom } from "@sunrise/error";
import { selectCanLogin } from "@sunrise/jwt";
import { PlayerError } from "@sunrise/player";
import {
  DialogButtonType,
  type ErrorDialogConfiguration,
  type GetErrorButtonFn,
  getErrorDialogConfiguration,
} from "@sunrise/yallo-error-mapping";
import { upsellLinkQueryAtom } from "@sunrise/yallo-upsell";

import { errorToShowInDialogAtom } from "./error-to-show-in-dialog.atom";

type UseErrorDialogPops = {
  getFocusKey?: () => string;
  getConfirmationDescription?: (
    dialog: ErrorDialogConfiguration,
    eventId?: string,
  ) => DialogDescription;
  skipSentryCapture?: boolean;
  doLogin?: () => void;
  shouldShowUpsellActions?: boolean;
};

/**
 * This component is responsible to show the errors that are put in the atoms which need to be shown to the users.
 * These errors are not really blocking errors but they may cause a certain interaction to fail.
 *
 * Whatever triggered the error should have also cleaned up after itself.
 * For example, if playout failed because the user's tokens were expired or because refresh showed the user no longer exists,
 * it should have already logged out the user.
 *
 * The only cleanup this component does is to remove the error from the state.
 */
export const useErrorDialog = ({
  getFocusKey,
  getConfirmationDescription,
  skipSentryCapture,
  doLogin,
  shouldShowUpsellActions = false,
}: UseErrorDialogPops) => {
  const dispatchError = useSetAtom(errorAtom);
  const { error, hidden } = useAtomValue(errorToShowInDialogAtom);
  const store = useStore();

  const dispatchConfirmationDialog = useSetAtom(dialogAtom);
  const [dialogId, setDialogId] = useState<string | null>(null);

  // So we do not handle the same error instance twice.
  const [errorHandled, setErrorHandled] = useState<Error | null>(null);

  // NOTE: This needs to be a stable dependency since else we will potentially log errors multiple times.
  const close = useAtomCallback(
    useCallback(
      (get): void => {
        dispatchError(null);
        dispatchConfirmationDialog(
          actionDialogClose({ id: get(selectCurrentlyOpenedDialog)?.id }),
        );
      },
      [dispatchConfirmationDialog, dispatchError],
    ),
  );

  const getButtonAction: GetErrorButtonFn = useCallback(
    (code?: DialogButtonType): (() => void) => {
      switch (code) {
        case DialogButtonType.OK:
        case DialogButtonType.CLOSE:
          return close;
        case DialogButtonType.REPLAY_SETTINGS:
          return () => {
            // TODO: Open replay settings once replay settings are there.
            close();
          };
        default:
          return () => {
            /* noop */
          };
      }
    },
    [close],
  );

  // Make sure to clear the relevant error modal we triggered as soon as the error is removed.
  useEffect(() => {
    if ((!error || hidden) && dialogId) {
      dispatchError(null);
      dispatchConfirmationDialog(actionDialogClose({ id: dialogId }));
      setDialogId(null);
    }
  }, [
    error,
    close,
    dialogId,
    dispatchConfirmationDialog,
    dispatchError,
    hidden,
  ]);

  useEffect(() => {
    if (!error || errorHandled === error) {
      return;
    }

    const doAsync = async (): Promise<void> => {
      const isBaseError = error instanceof BaseError;

      // When the error indicates we should trace, then let's trace it.
      // The error will no longer be sent to the ErrorBoundary.
      const shouldTrace = isBaseError ? error.shouldTrace : true;
      let eventId: string | undefined;

      if (shouldTrace && !skipSentryCapture) {
        eventId = captureException(error, {
          tags: {
            errorCode: isBaseError ? error.errorCode : null,
            location: "error_dialog",
            "player.error": error instanceof PlayerError,
            // "player.error": "isPlayerError" in error,
            hidden,
          },
          extra: isBaseError ? error.extras : undefined,
          level: hidden ? "info" : "error",
        });
      }

      if (skipSentryCapture) {
        // eslint-disable-next-line no-console
        console.info(
          `captureException (${shouldTrace ? "shouldTrace" : "skipped"})`,
          { eventId, hidden },
          error,
          isBaseError
            ? {
                extras: error.extras,
                errorCode: error.errorCode,
                cause: error.cause,
              }
            : undefined,
        );
      }

      // When the dialog is hidden, we don't want to show it.
      if (hidden) {
        setErrorHandled(error);
        return;
      }

      // We force to always have a dialog through forceResult.
      // So whenever an error is set on the error state, we will at least show the general error, even if there is no mapping found.
      const dialog = await getErrorDialogConfiguration(
        error,
        getButtonAction,
        store,
        {
          forceResult: true,
          eventId,
        },
      );

      if (dialog) {
        const actions: ActionDialogAction[] = [];

        if (dialog.confirmationLabel && dialog.onConfirm) {
          actions.push({
            label: dialog.confirmationLabel,
            action: dialog.onConfirm,
            key: "confirm",
            initialFocus: !dialog.focusReject,
          });
        }

        if (dialog.upsell && shouldShowUpsellActions) {
          const canLogin = store.get(selectCanLogin);
          if (canLogin && doLogin) {
            actions.push({
              label: { key: "login_button" },
              action: doLogin,
              key: "login",
              initialFocus: false,
            });
          }

          const { data: upgrade } = await store.get(
            upsellLinkQueryAtom(dialog.upsell),
          );

          if (upgrade) {
            actions.push({
              label: { key: "upgrade_button" },
              url: upgrade.upsell_link,
              key: "upgrade",
              initialFocus: false,
            });
          }
        }

        if (dialog.rejectionLabel && dialog.onReject) {
          actions.push({
            label: dialog.rejectionLabel,
            action: dialog.onReject,
            key: "reject",
            initialFocus: !!dialog.focusReject,
          });
        }

        dispatchConfirmationDialog(
          actionDialogOpen({
            title: dialog.title,
            description: dialog.isGeneralError
              ? (getConfirmationDescription?.(dialog, eventId) ??
                dialog.description)
              : dialog.description,
            type: "actions",
            backBehaviour: dialog.backBehaviour,
            actions,
            lastFocusKey: getFocusKey?.() ?? "",
            id: dialog.id,
            technicalErrorName: dialog.technicalErrorName,
          }),
        );

        setDialogId(dialog.id);
        setErrorHandled(error);
      }
    };

    void doAsync();
  }, [
    errorHandled,
    dispatchConfirmationDialog,
    dispatchError,
    error,
    getButtonAction,
    store,
    hidden,
    skipSentryCapture,
    doLogin,
    getConfirmationDescription,
    getFocusKey,
    shouldShowUpsellActions,
  ]);

  return {
    dialogId,
  };
};

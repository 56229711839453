import type { HTMLProps, PropsWithChildren, ReactElement } from "react";
import clsx from "clsx";

import { Text } from "../../text";
import styles from "./toggle.module.css";

type Props = {
  type: "radio" | "checkbox";
  labelPosition?: "start" | "end";
  variant?: "normal" | "switch" | "selection";
  name?: string;
  value?: string;
  onChange?: (name: string, value: string, checked: boolean) => void;
  disabled?: boolean;
  error?: boolean;
  checked?: boolean;
  defaultChecked?: boolean;
  showIcon?: boolean;
} & HTMLProps<HTMLInputElement> &
  PropsWithChildren &
  Pick<CommonProps, "className" | "data-testid">;

function Toggle({
  className,
  labelPosition,
  checked,
  children,
  defaultChecked,
  disabled,
  error,
  name,
  onChange,
  showIcon,
  type,
  value,
  variant,
  htmlFor,
  "data-testid": dataTestId,
}: Props): ReactElement {
  return (
    <label
      className={clsx(
        styles.label,
        labelPosition === "end" && styles.inverted,
        className,
      )}
      data-testid={dataTestId}
      htmlFor={htmlFor}
    >
      {typeof children === "string" ? (
        <Text size="medium" variant="body">
          {children}
        </Text>
      ) : (
        children
      )}
      <input
        checked={checked}
        className={clsx(
          styles.input,
          variant && ["switch", "selection"].includes(variant)
            ? styles[variant]
            : styles[type],
          error && styles.error,
          showIcon && styles.showIcon,
        )}
        defaultChecked={defaultChecked}
        disabled={disabled}
        name={name}
        role={variant === "switch" ? "switch" : undefined}
        type={type}
        value={value}
        onChange={
          onChange
            ? (e) =>
                onChange(
                  e.currentTarget.name,
                  e.currentTarget.value,
                  e.currentTarget.checked,
                )
            : undefined
        }
      />
    </label>
  );
}

export { Toggle };

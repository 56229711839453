import type { Nullable } from "@sunrise/utils";
import type { PlayRequest } from "@sunrise/yallo-player-types";

import { PlayerError } from "./player.error";

/**
 * Throw this error for player issues that indicate a reload of the stream may be the best option.
 * Clients should see if an error like this is detected and then automatically reload the stream.
 */
export class CriticalPlayerError extends PlayerError {
  constructor(
    e: unknown,
    playRequest?: Nullable<PlayRequest>,
    streamUrl?: Nullable<string>,
  ) {
    super(e, playRequest, streamUrl);
    this.name = "CriticalPlayerError";
  }

  override get shouldAutoRecover(): boolean {
    return true;
  }
}

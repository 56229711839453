import { clsx } from "clsx";

import styles from "./search-result.module.css";

export function SearchResultSkeleton() {
  return (
    <div className={clsx(styles.container, styles.skeleton)}>
      <div className={clsx(styles.container, styles.epgInformation)}>
        <div className={styles.meta}>
          <div className={styles.header}>
            <div className={clsx("skeleton-item", styles.title)}></div>
            <div className={clsx("skeleton-item", styles.subtitle)}></div>
          </div>
          <div className={clsx("skeleton-item", styles.airTime)} />
        </div>
      </div>
      <div className={clsx("skeleton-item", styles.logo)} />
    </div>
  );
}

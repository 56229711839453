import { useAtomValue } from "jotai";

import { selectCanLogin } from "@sunrise/jwt";
import { useTranslator } from "@sunrise/translator";
import { upgradeLinkAtom } from "@sunrise/yallo-upsell";
import { Button, Link } from "@sunrise/yallo-web-components";

import { login } from "@/core";

import styles from "./login-button.module.css";

export function LoginOrUpgradeButton() {
  const t = useTranslator();
  const canUserLogin = useAtomValue(selectCanLogin);
  const upgradeLink = useAtomValue(upgradeLinkAtom);

  if (canUserLogin) {
    return (
      <Button
        className={styles.authButton}
        data-testid="login-button"
        onClick={() => login()}
      >
        {t("login_button")}
      </Button>
    );
  }

  if (upgradeLink) {
    return (
      <Link
        className={styles.authButton}
        data-testid="upgrade-button"
        href={upgradeLink}
        variant="tonal"
      >
        {t("upgrade_button")}
      </Link>
    );
  }

  return null;
}
